@use "shared/_fonts" as fonts;

body {
  min-height: 100vh;
  margin: 0;
  font-family: fonts.$font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #BADCDE;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield; 
  -moz-appearance: textfield; 
  -webkit-appearance: textfield; 
}